.header {
  padding: 1rem 2rem;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  border-bottom: 1px solid #ddd;


  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .btn {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      cursor: pointer;
    }

    .red {
      background-color: red;
    }
    .yellow {
      background-color: rgb(198, 198, 3);
    }
    .green {
      background-color: green;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .menu {
      background-color: transparent;
      border: none;
      color: white;
      cursor: pointer;
      padding: 0.5rem 2rem;
      transition: all 300ms ease-in-out;

      &:hover {
        background-color: #ddd;
        color: #000;
      }

      @media(max-width: 576px){
        padding: 0.5rem;
      }
    }
  }
}
