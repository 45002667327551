@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$color-white: #fff;
$color-black: #000;
$color-gray: #ddd;
$color-bg-gradient: linear-gradient(
  0deg,
  rgba(0, 0, 0, 0.8),
  rgba(0, 0, 0, 0.8)
);
$font-family: "Roboto Mono", monospace;
$font-size-xl: 3rem;
$font-size-lg: 2.5rem;
$font-size-md: 1.6rem;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font-family;
}

body {
  color: $color-white;
  background-color: $color-black;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 8rem;

  @media (max-width: 576px) {
    margin: 2rem;
  }

  .container {
    width: 100%;
    background: $color-bg-gradient, url("../../assets/background4.gif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $color-gray;
    padding-bottom: 2rem;

    @media (max-width: 576px) {
      margin: 1rem;
    }

    .content {
      display: flex;
      justify-content: center;
      margin: 2rem 5rem 0rem 5rem;
      gap: 5rem;

      .left,
      .right {
        flex: 1;
      }

      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 1rem 3rem;
      }

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        @media (max-width: 576px) {
          display: flex;
          flex-direction: column;
        }

        .contactContainer {
          border: 1rem solid transparent;
          overflow: hidden;

          img {
            width: 40rem;
            height: 50rem;
          }

          @media (max-width: 576px) {
            display: none;
          }
        }

        .colTwo {
          margin: 3rem 0rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;

          @media (max-width: 576px) {
            flex-direction: row;
          }

          a {
            text-decoration: none;
            color: $color-white;
          }

          svg {
            position: relative;
            cursor: pointer;
            font-size: $font-size-lg;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;

        .colOne {
          .heading {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }

          .formGroup {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;

            .formControl {
              display: flex;
              flex-direction: column;

              label {
                font-size: 1.6rem;
              }

              input {
                margin-top: 1rem;
                width: 100%;
                height: 4rem;
                background-color: transparent;
                border: 1px solid #ddd;
                color: #fff;
                padding: 0.5rem;

                &:focus {
                  background-color: transparent;
                  color: #fff;
                }

                &:active {
                  background-color: transparent;
                  color: #fff;
                }
              }

              .submit {
                padding: 0.8rem 2rem;
                cursor: pointer;
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}
