@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$color-white: #fff;
$color-black: #000;
$color-gray: #ddd;
$color-bg-gradient: linear-gradient(
  0deg,
  rgba(0, 0, 0, 0.8),
  rgba(0, 0, 0, 0.8)
);
$font-family: "Roboto Mono", monospace;
$font-size-xl: 3rem;
$font-size-lg: 2.5rem;
$font-size-md: 1.6rem;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font-family;
}

body {
  color: $color-white;
  background-color: $color-black;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 8rem;

  @media (max-width: 576px) {
    margin: 2rem;
  }

  .container {
    width: 100%;
    background: $color-bg-gradient, url("../../assets/background4.gif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $color-gray;
    padding-bottom: 2rem;

    @media (max-width: 576px) {
      margin: 1rem;
    }

    .content {
      display: flex;
      margin: 1rem 5rem 1rem 5rem;
      padding-bottom: 2rem;
      gap: 10rem;
      border-bottom: 0.2rem solid $color-gray;

      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 1rem 3rem;
      }

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10rem;

        @media (max-width: 576px) {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }

        .imageContainer {
          background-color: $color-white;
          border-radius: 50%;
          border: 0.2rem solid $color-gray;
          overflow: hidden;

          img {
            width: 20rem;
            height: 20rem;
            object-fit: cover;
          }
        }

        .colTwo {
          margin: 3rem 0rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;

          @media (max-width: 576px) {
            flex-direction: row;
          }

          a {
            text-decoration: none;
            color: $color-white;
          }

          svg {
            position: relative;
            cursor: pointer;
            font-size: $font-size-lg;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;

        .colOne {
          .name {
            font-size: $font-size-xl;
            font-weight: bold;

            @media (max-width: 576px) {
              font-size: $font-size-lg;
            }
          }

          .description {
            margin-top: 2rem;
            font-size: $font-size-md;
          }
        }

        .colThree {
          .downloadCV {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: 1px solid #ddd;
            gap: 1rem;
            margin-top: 2rem;
            padding: 1rem 2rem;
            transition: all 500ms ease-in-out;

            span {
              font-size: 1.6rem;
            }
            svg {
              font-size: 2rem;
            }

            &:hover {
              background-color: transparent;
              color: #fff;
              border: 1px solid #ddd;
            }
          }
        }
      }
    }

    .toolsAndTechs {
      margin: 2rem 5rem 1rem 5rem;

      .heading {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;

        svg {
          font-size: 2rem;
        }

        span {
          font-weight: bold;
          font-size: 1.8rem;
        }
      }

      @media (max-width: 576px) {
        margin: 2rem 3rem;

        .heading {
          gap: 1rem;
          font-size: 1rem;
        }
      }

      .icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        font-size: 4rem;

        .c {
          color: #659bd3;
        }

        .python {
          color: #306998;
        }

        .javascript {
          color: #f0db4f;
        }

        .django {
          color: #0c4b33;
        }

        .react {
          color: #61dafb;
        }

        .html {
          color: #dc4a25;
        }

        .css {
          color: #254adc;
        }

        .nextjs {
          color: #ffffff;
        }

        .sass {
          color: #cd6799;
        }

        .postgresql {
          color: #336791;
        }

        .postman {
          color: #ff6c37;
        }

        .git {
          color: #f05032;
        }

        .docker {
          color: #0db7ed;
        }

        .firebase {
          color: #ffca28;
        }

        .nginx {
          color: #269539;
        }

        .linux {
          color: #fcc624;
        }

        .api {
          color: #e5701e;
        }
      }
    }
  }
}
